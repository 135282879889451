import React from 'react';
import Seo from '../components/seo';
import TermsSec from '../containers/Terms';
import { ResetCSS } from 'common/src/assets/css/style';

const TermsPage = () => (
  <>
    <Seo title="Terms of Service" />
    <ResetCSS />
    <TermsSec></TermsSec>
  </>
);

export default TermsPage;
